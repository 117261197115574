<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>Viewing: How We Recruit</v-card-title>
          <v-card-text>
            <TableCrudComponent endpoint="/api/how-we-recruit" table-name="How We Recruit Custom Section"></TableCrudComponent>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TableCrudComponent from "@/components/tables/TableCrudComponent";
export default {
  name: "HowWeRecruitView",
  components: {TableCrudComponent}
}
</script>

<style scoped>

</style>
